import React, {useEffect, useState} from "react";
import PostsContainer from "../components/postsContainer";
import FilterComponent from "../components/filterComponent";
import {Col, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {Link, useParams} from "react-router-dom";
import {ArchivePayload, setCategory, setDate, setTag} from "../features/filter-slice";
import {ImageLoader} from "../components/Loaders";
import NavBar from "../components/NavBar";
import {useFetchConfigQuery} from "../features/news-api-slice";
import MetaDataHelmet from "../helmets/MetaDataHelmet";

const defaultHeader = "HIDIVE News";

const NewsRoute = () => {

    const [header, setHeader] = useState(defaultHeader);
    const filter = useAppSelector(state => state.filter);
    const dispatch = useAppDispatch();
    const params = useParams();
    const {data = null} = useFetchConfigQuery(useParams());

    useEffect(() => {
        dispatch(setTag(params.tag));
        dispatch(setCategory(params.category));
        dispatch(setDate({month: params.month, year: params.year} as ArchivePayload))
    }, [dispatch, params]);

    useEffect(() => {
        let headerParts = [];
        if (filter.archive) {
            let date = new Date(filter.archive.year, filter.archive.month - 1, 1);
            headerParts.push(date.toLocaleDateString('default', {
                month: "long",
                year: "numeric"
            }));
        }
        if (filter.category) headerParts.push(filter.category.replaceAll("-", " "));
        if (filter.tag) headerParts.push(filter.tag.replaceAll("-", " "));
        if (headerParts.length === 0) headerParts.push(defaultHeader);

        setHeader(headerParts.join(' '));
    }, [filter]);

    return (
        <>
            { data ? <MetaDataHelmet title={data.metaData.title} description={data.metaData.description} keywords={data.metaData.keywords} type={data.metaData.type} /> : null}
            <NavBar>
                <Link to={data?.banner.link ?? '/'}>
                    <ImageLoader src={data?.banner.url ?? ''}
                                 alt={data?.banner.link ?? ''}
                                 height={275}
                                 width={1140}/>
                </Link>
                <div className='px-3'>
                    <Row xs='12' s='6' className='my-3'>
                        <Col>
                            <h1 className='h3'>{header}</h1>
                        </Col>
                        <Col className='text-end'>
                            <FilterComponent/>
                        </Col>
                    </Row>
                    <PostsContainer/>
                </div>
            </NavBar>
        </>
    );
};

export default NewsRoute;