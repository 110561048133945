import {PostExcerpt} from "../features/news-api-slice";
import React, {useState} from "react";
import '../styles/posts.scss';
import {Link} from "react-router-dom";
import {Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from "reactstrap";
import {Loader} from "./Loaders";
import DateComponent from "./DateComponent";

interface PostProps {
    post: PostExcerpt;
}

const PostSummary = ({post}: PostProps) => {
    const [imgLoading, setImgLoading] = useState(true);

    return (
        <div className="col-12 col-sm-6 col-lg-4">
            <Link to={post.seoUrl}>
                <Card className='mb-4'>
                    <Loader height={485} width={863} loading={imgLoading}>
                        <div className='img-grow'>
                            <img className='img-fluid' src={post.image} alt={post.title} onLoad={ () => setImgLoading(false) }/>
                        </div>
                    </Loader>
                    <CardBody>
                        <div className='mb-1'>
                            <CardTitle>{post.title}</CardTitle>
                            <CardSubtitle>
                                <DateComponent date={post.releaseDate}/>
                            </CardSubtitle>
                        </div>
                        <CardText>{post.excerpt}</CardText>
                    </CardBody>
                    <CardFooter>MORE ►</CardFooter>
                </Card>
            </Link>
        </div>
    );
};

export default PostSummary;
