import {ImageLoader} from "../components/Loaders";
import {Link} from "react-router-dom";
import Config from "../config";

const PlaceHolder = () => {
    return (
        <div className='text-center '>
            <ImageLoader src={"https://static.hidive.com/Content/images/EatBingeSleepRepeatBackground.png"} alt='HIDIVE'
                         height={1500} width={2000}/>
            <Link to={Config.apiUrl}>
                <div style={{position: 'absolute', top: '10%', width: '300px', left: '15%'}}>
                    <ImageLoader src={"https://static.hidive.com/Content/images/HIDIVE_logo_white_blue.svg"}
                                 alt='HIDIVE' height={73} width={300}/>
                </div>
            </Link>
        </div>
    )
};

export default PlaceHolder;