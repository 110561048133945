import {Container} from "reactstrap";
import {PropsWithChildren} from "react";

const ContainerLayout = ({children}: PropsWithChildren) => {
    return (
        <Container>
            <div className='bg-dark mb-3 pb-3' style={{minHeight: '100vh'}}>
                {children}
            </div>
        </Container>
    );
}

export default ContainerLayout;
