import {MetaData} from "../features/news-api-slice";
import {Helmet} from "react-helmet-async";
import Config from "../config";

const MetaDataHelmet = (data: MetaData) => {
    return (
        <Helmet>
            <meta property='title' content={data.title}/>
            <meta property='description' content={data.description}/>
            <meta property='keywords' content={data.keywords}/>
            <meta property='title' content={data.title}/>
            <meta property="og:title" content={data.title}/>
            <meta property="og:description" content={data.description}/>
            <meta property="og:type" content={data.type}/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:image" content={Config.logoImage}/>
            <meta property="og:site_name" content="HIDIVE"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="HIDIVE"/>
            <meta name="twitter:title" content={data.title}/>
            <meta name="twitter:description" content={data.description}/>
            <meta name="twitter:image:src" content={Config.logoImage}/>
        </Helmet>
    )
};

export default MetaDataHelmet;