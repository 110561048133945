import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons"
import {Col, Container, Row, Spinner} from "reactstrap";
import {PropsWithChildren} from "react";
import ContainerLayout from "./ContainerLayout";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

export interface NavProps {
    home: string,
    previous?: string,
    next?: string,
    loading: boolean
}

const Navigation = ({home, previous, next, loading, children}: PropsWithChildren<NavProps>) => {
    return (
        <>
            <Container>
                <Row className='post-nav my-3'>
                    <Col xs='1'>
                        {loading ? <Spinner>Loading...</Spinner> : null}
                    </Col>
                    <Col xs='4'></Col>
                    <Col xs='1'>
                        {!!previous ? (
                            <Link to={previous}>
                                <FontAwesomeIcon icon={faChevronLeft}/>
                            </Link>
                        ) : null}
                    </Col>
                    <Col xs='1'>
                        {!!next ? (
                            <Link to={next}>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </Link>
                        ) : null}
                    </Col>
                    <Col xs='4'></Col>
                    <Col xs='1' className='text-end'>
                        <Link to={home}>
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </Link>
                    </Col>
                </Row>
            </Container>
            <ContainerLayout>{children}</ContainerLayout>
        </>
    );
};

export default Navigation;
