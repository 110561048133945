import ContainerLayout from "../components/ContainerLayout";
import {Link, useRouteError} from "react-router-dom";
import {Notice, NoticeType} from "../components/Notice";
import Config from "../config";

const Error = () => {
    const error = useRouteError();

    console.error(error);

    return (
        <ContainerLayout>
            <div className='text-center pt-5'>
                <Link to={Config.apiUrl}>
                    <Notice type={NoticeType.ServiceUnavailable}/>
                </Link>
            </div>
        </ContainerLayout>
    )
}

export default Error;