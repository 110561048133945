import {Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useAppSelector} from "../app/hooks";
import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useFetchConfigQuery} from "../features/news-api-slice";
import {Link, useParams} from "react-router-dom";

const Categories = () => {
    const {category, archive} = useAppSelector(state => state.filter);
    const {data = null, isFetching} = useFetchConfigQuery(useParams());

    let path = (category?: string) => {
        let parts = [''];
        if (archive) {
            parts.push(`${archive.year}`);
            parts.push(`${archive.month}`)
        }
        if (category) parts.push(category);
        if (parts.length === 1) parts.push('');
        return parts.join('/');
    }

    let categoryItems = () => {
        return data?.categories.map(category =>
            <Link to={path(category.seoName)} key={category.name}>
                <DropdownItem key={category.name}>
                    {category.name}
                </DropdownItem>
            </Link>
        ) ?? [];
    };

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle caret
                                color='primary'
                                disabled={isFetching}
                                style={category ? {borderTopRightRadius: 0, borderBottomRightRadius: 0} : {}}>
                    Categories
                </DropdownToggle>
                {category ?
                    <Link to={path()}>
                        <Button color='dark' style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                            X
                        </Button>
                    </Link> :
                    null
                }
                <DropdownMenu end dark>
                    {categoryItems()}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    )
}

const Archive = () => {
    const {archive, category} = useAppSelector(state => state.filter);

    const params = useParams();

    const startDate = params.year &&
        params.month &&
        !isNaN(Number(params.year)) &&
        !isNaN(Number(params.month)) ?
            new Date(Number(params.year), Number(params.month) - 1) :
            new Date();

    const [localDate, setLocalDate] = useState(startDate);

    let path = (date?: Date) => {
        let parts = [''];
        if (date) parts.push(`${date.getFullYear()}/${date.getMonth() + 1}`);
        if (category) parts.push(category);
        if (parts.length === 1) parts.push('');
        return parts.join('/');
    }

    return (
        <UncontrolledDropdown>
            <DropdownToggle caret
                            color='primary'
                            style={archive ? {borderTopRightRadius: 0, borderBottomRightRadius: 0} : {}}>
                { archive ? new Date(localDate).toLocaleString("default", {
                    month: "long",
                    year: "numeric"
                }) : 'Archive' }
            </DropdownToggle>
            {archive ?
                <Link to={path()}>
                    <Button color='dark' style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                        X
                    </Button>
                </Link>
                :
                null
            }
            <DropdownMenu end dark className='p-3 mt-1 text-center'>
                <DatePicker
                    selected={localDate}
                    showMonthYearPicker
                    dateFormat="MM/dd/yyyy"
                    onChange={date => { if (date) setLocalDate(date) }}
                    maxDate={new Date()}
                    inline
                />
                <Button>
                    <Link to={path(localDate)}>Apply</Link>
                </Button>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const FilterComponent = () => {
    return (
        <ButtonGroup>
            <Categories/>
            <div className='ps-2'>
                <Archive/>
            </div>
        </ButtonGroup>
    )
}

export default FilterComponent;
