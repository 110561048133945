import {useEffect, useMemo} from "react";
import {useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import {useFetchConfigQuery} from "../features/news-api-slice";
import Config from "../config";

const tagManagerArgs = {
    gtmId: 'GTM-K99CSQZ',
    events: {
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    }
}

declare global {
    interface Window {
        hidive: {
            analytics : {
                trigger: (event: string, data: any) => void
            }
        }
    }
}

const Analytics = () => {
    const params = useParams();
    const { data, isFetching } = useFetchConfigQuery(params);
    const { pathname } = useLocation();

    const useAnalytics = useMemo(() => {
        if (!data) return false;
        if (params.seoUrl) return data.analytics.post;
        if (params.tag) return data.analytics.tag;
        if (params.category === 'press') return data.analytics.press;
        return data.analytics.index;
    }, [params, data]);

    useEffect(() => {
        if (!useAnalytics) return;

        TagManager.initialize(tagManagerArgs);
        ReactGA.initialize(Config.gaMeasurementId);
    }, [useAnalytics]);

    useEffect(() => {
        if (!useAnalytics) return;

        let dataLayerName = 'viewContent';
        let dataLayer = {
            content_category: "News",
            content_name: pathname
        };

        window.hidive = window.hidive || {};
        window.hidive?.analytics?.trigger(dataLayerName, dataLayer);
        TagManager.dataLayer({dataLayerName, dataLayer});
        ReactGA.event(dataLayerName, dataLayer);
    }, [pathname, useAnalytics]);

    if (isFetching || !data || !useAnalytics) return null;

    return <Helmet>
        <script src="/analytics/facebook.js"/>
        <script src="/analytics/tiktok.js"/>
        <script src="/analytics/twitter.js"/>
        <script src="/analytics/hidive.js"/>
    </Helmet>;
}

export default Analytics;
