import {configureStore} from "@reduxjs/toolkit";
import {newsApiSlice} from "../features/news-api-slice";
import {filterSlice} from "../features/filter-slice";

export const store = configureStore({
    reducer: {
        filter: filterSlice.reducer,
        [newsApiSlice.reducerPath]: newsApiSlice.reducer
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware().concat(newsApiSlice.middleware)
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
