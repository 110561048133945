import React, {PropsWithChildren, useState} from "react";
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import ContainerLayout from "./ContainerLayout";
import Config from "../config";
import {LinkResources, useFetchConfigQuery} from "../features/news-api-slice";
import {useParams} from "react-router-dom";

const NavBar = ({children}: PropsWithChildren) => {
    const [isOpen, setIsOpen] = useState(false);
    const {data = null} = useFetchConfigQuery(useParams());

    let resources : LinkResources = data ? data.linkResources : {
        home: "/",
        simulcasts: "/simulcasts",
        dubs: "/dubs",
        series: "/series",
        movies: "/movies"
    };

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <Navbar dark={true} color='black' container={true} fixed='top' expand={'md'} className='py-2 bg-opacity-75'>
                <NavbarBrand href={resources.home}>
                    <img src={Config.logoImage} alt="HIDIVE"/>
                </NavbarBrand>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink href={resources.simulcasts}>Simulcasts</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={resources.dubs}>Dubs</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={resources.series}>Series</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={resources.movies}>Movies</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={resources.home}>Home</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                More
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem>
                                    <NavLink href='/'>News</NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLink href='https://www.sentaifilmworks.com'>Shop</NavLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
            <main>
                <ContainerLayout>{children}</ContainerLayout>
            </main>
        </>
    );
}

export default NavBar;
