import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ArchiveFilter {
    month: number,
    year: number
}

export interface Filter {
    category?: string;
    archive?: ArchiveFilter;
    tag?: string;
}

export interface FilterRequest {
    skip: number;
    take: number;
    filter: Filter;
}

export interface ArchivePayload {
    month: string,
    year: string
}

const initialState: Filter = {
    category: undefined,
    archive: undefined,
    tag: undefined
}

export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<string | undefined>) => {
            state.category = action.payload
        },
        setDate: (state, action: PayloadAction<ArchivePayload>) => {

            let month = Number(action.payload.month);
            let year  = Number(action.payload.year);

            if (!isNaN(month) && !isNaN(year)) {
                state.archive = {
                    month: Number(action.payload.month),
                    year: Number(action.payload.year)
                };
            } else {
                state.archive = undefined;
            }
        },
        setTag: (state, action: PayloadAction<string | undefined>) => {
            state.tag = action.payload;
        }
    }
})

export const {
    setCategory,
    setDate,
    setTag,
} = filterSlice.actions;
