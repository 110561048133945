import {Image} from "react-bootstrap";
import React, {createRef, PropsWithChildren, useEffect, useState} from "react";
import {Placeholder} from "reactstrap";


export interface LoadingImageProps {
    src: string;
    alt: string;
    height: number;
    width: number;
}

export const ImageLoader = ({src, alt, height, width}: LoadingImageProps) => {
    const [loading, setLoading] = useState(true);

    return (
        <Loader loading={loading} width={width} height={height}>
            <Image className='img-fluid'
                   src={src}
                   alt={alt}
                   onLoad={() => setLoading(false)}
                   style={loading ? {display: 'none'} : {}}
            />
        </Loader>
    );
}

export interface LoadingProps {
    height: number;
    width: number;
    loading: boolean;
}

const PlaceHolder = ({height, loading}: { height: number, loading: boolean }) => {
    if (loading) return (
        <Placeholder animation="glow" tag="span">
            <Placeholder xs={12} style={{height: `${height}px`, color: "white"}}/>
        </Placeholder>
    )

    return null;
}

export const Loader = ({height, width, loading, children}: PropsWithChildren<LoadingProps>) => {
    const [displayWidth, setDisplayWidth] = useState(0);
    const refContainer = createRef<HTMLDivElement>();

    useEffect(() => {
        if (loading && refContainer.current) {
            setDisplayWidth(refContainer.current.offsetWidth)
        }
    }, [loading, refContainer]);

    let displayHeight = height / width * displayWidth;

    return (
        <span ref={refContainer}>
            <PlaceHolder height={displayHeight ?? height} loading={loading}/>
            <span style={{display: loading ? 'none' : 'block'}}>
                {children}
            </span>
        </span>
    );
}