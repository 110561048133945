interface Configuration {
    apiUrl: string;
    usePlaceHolder: boolean;
    gaMeasurementId: string;
    logoImage: string;
}

const logoImage = "//static.hidive.com/misc/HIDIVE-Logo-White.png";

const local = {
    apiUrl: 'https://localhost:58185',
    gaMeasurementId: 'G-3T7TTRCH2Q',
    logoImage: logoImage
}

const dev = {
    apiUrl: 'https://dev-apigw.lojump.xyz',
    gaMeasurementId: 'G-Z020B3SEFH',
    logoImage: logoImage
}

const qa = {
    apiUrl: 'https://qa-apigw.lojump.xyz',
    gaMeasurementId: 'G-103RZWL8Q3',
    logoImage: logoImage
}

const prod = {
    apiUrl: 'https://apigw.hidive.com',
    gaMeasurementId: 'G-CN3DSB3844',
    logoImage: logoImage
}

const stage = {
    ...prod,
    apiUrl: 'https://stage-apigw.hidive.com'
}

const GetConfiguration = () => {
    switch (process.env.REACT_APP_STAGE) {
        case 'prod':
            return prod;
        case 'stage':
            return stage
        case 'qa':
            return qa;
        case 'dev':
            return dev;
        default:
            return local;
    }
}

let config = GetConfiguration();

let result: Configuration = {
    ...config,
    usePlaceHolder: process.env.REACT_APP_USE_PLACEHOLDER === 'true'
}

export default result;
