import React from "react";
import {Link} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, List, ListInlineItem, UncontrolledDropdown} from "reactstrap";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {faFacebookF, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faLink} from "@fortawesome/free-solid-svg-icons";

const ShareLinks = ({title}: { title: string }) => {
    let href = window.location.href;
    return (
        <div className='mt-5'>
            <List type='inline' className="text-center text-uppercase">
                <FacebookShare href={href}/>
                <TwitterShare title={title} href={href}/>
                <ArticleLink href={href}/>
            </List>
        </div>
    );
};

const FacebookShare = ({href}: { href: string }) => {
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${href}`;

    return (
        <ListInlineItem>
            <Link to={facebookLink} target='_blank' rel='noreferrer' className='share-link'>
                <BoxedIcon icon={faFacebookF}/>{" "}
                <span className="hidden-xs">Share on </span>Facebook
            </Link>
        </ListInlineItem>
    );
};

const TwitterShare = ({title, href}: { title: string, href: string }) => {
    const twitterLink = `https://twitter.com/intent/tweet?status=${title}+${href}`;

    return (
        <ListInlineItem>
            <Link to={twitterLink} target='_blank' rel='noreferrer' className='share-link'>
                <BoxedIcon icon={faTwitter}/>{" "}
                <span className="hidden-xs">Share on </span>X
            </Link>
        </ListInlineItem>
    );
};

const ArticleLink = ({href}: { href: string }) => {
    return (
        <ListInlineItem className="hidden-xs hidden-sm hidden-md">
            <UncontrolledDropdown
                className="me-2"
                direction="up"
            >
                <DropdownToggle caret className='share-link'>
                    <BoxedIcon icon={faLink}/>{" "}
                    Link <span className="hidden-xs">to Article</span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>
                        (Ctrl + c) to copy link
                    </DropdownItem>
                    <DropdownItem>
                        <input
                            type="text"
                            onFocus={e => e.target.select()}
                            className="form-control"
                            defaultValue={href}
                        />
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </ListInlineItem>
    );
};

const BoxedIcon = ({icon}: FontAwesomeIconProps) => {
    return (
        <div className='share-icon'>
            <FontAwesomeIcon icon={icon}/>
        </div>
    )
}

export default ShareLinks;
