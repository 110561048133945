import React from "react";

const DateComponent = ({date}: { date: Date }) => {
    let d = new Date(date);

    return (
        <div className="date">{d.toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        })}</div>
    )
}

export default DateComponent;