import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import NewsRoute from "./routes/NewsRoute";
import './styles/style.scss';
import DetailRoute from "./routes/DetailRoute";
import App from "./App";
import Placeholder from './routes/PlaceHolder';
import Error from './routes/Error';
import Configuration from "./config";
import {HelmetProvider} from "react-helmet-async";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

if (Configuration.usePlaceHolder) {
    const placeHolderRouter = createBrowserRouter([
        {
            path: '/',
            element: <App/>,
            errorElement: <Placeholder/>,
            children: [
                {
                    path: '/',
                    element: <Placeholder/>
                }
            ]
        }
    ])

    root.render(
        <React.StrictMode>
            <RouterProvider router={placeHolderRouter}/>
        </React.StrictMode>
    );
} else {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <App/>,
            errorElement: <Error/>,
            children: [
                {
                    path: '/*?',
                    element: <NewsRoute/>
                },
                {
                    path: '/tag/:tag',
                    element: <NewsRoute/>
                },
                {
                    path: '/:category',
                    element: <NewsRoute/>
                },
                {
                    path: '/:year/:month',
                    element: <NewsRoute/>
                },
                {
                    path: '/:year/:month/:category',
                    element: <NewsRoute/>
                },
                {
                    path: '/:year/:month/:day/:seoUrl',
                    element: <DetailRoute/>
                }
            ]
        }
    ]);

    root.render(
        <React.StrictMode>
            <HelmetProvider>
                <RouterProvider router={router}/>
            </HelmetProvider>
        </React.StrictMode>
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
