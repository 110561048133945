import {Byline, NewsDetails, Tag} from "../features/news-api-slice";
import {Badge, Container} from "reactstrap";
import {ImageLoader} from "./Loaders";
import ShareLinks from "./ShareIcons";
import parse, {Element, HTMLReactParserOptions} from "html-react-parser";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import DateComponent from "./DateComponent";
import MetaDataHelmet from "../helmets/MetaDataHelmet";

export interface PostDetailProps {
    details: NewsDetails
}

const PostDetail = ({details}: PostDetailProps) => {
    let {post, byline, tags, disclaimer, metaData} = details;
    return (
        <Container className='w-75 py-5'>
            <MetaDataHelmet title={metaData.title} description={metaData.description} keywords={metaData.keywords} type={metaData.type} />
            <div className="pb-5">
                <ImageLoader src={post.image} alt={post.title} height={485} width={863}/>
            </div>
            <h1>{post.title}</h1>
            <PostByline byline={byline}/>
            <DateComponent date={post.releaseDate}/>
            <hr/>
            <PostContent content={post.content}/>
            <hr/>
            <AuthorBrief byline={byline}/>
            <PostTags tags={tags}/>
            <ShareLinks title={post.title}/>
            <PostDisclaimer disclaimer={disclaimer}/>
        </Container>
    )
};

const PostContent = ({content}: { content: string }) => {
    const wrapperClass = "embed-responsive text-center";
    const embedClass = "embed-responsive-item";
    const originalHidiveNewsLink = "https://www.hidive.com/news";

    const options: HTMLReactParserOptions = {
        replace(domNode) {
            if (
                domNode instanceof Element &&
                domNode.attribs.class === 'remove'
            ) {
                return <></>;
            }

            if (domNode instanceof Element && domNode.name === 'img') {
                let {src, alt, height, width} = domNode.attribs;

                let heightNum = isNaN(Number(height)) ? 100 : Number(height);
                let widthNum = isNaN(Number(width)) ? 300 : Number(width);

                return <ImageLoader src={src} alt={alt} height={heightNum} width={widthNum}/>
            }

            if (domNode instanceof Element && domNode.children && domNode.children.length === 1) {
                let child = domNode.children[0];
                if (
                    child instanceof Element &&
                    child.name === "iframe" &&
                    child.attribs.src.toLowerCase().indexOf("youtube") > -1
                ) {
                    let currentClass = domNode.attribs.class;
                    let childClass = child.attribs.class;

                    domNode.attribs.class = currentClass
                        ? currentClass + " " + wrapperClass
                        : wrapperClass;
                    child.attribs.class = childClass
                        ? childClass + " " + embedClass
                        : embedClass;
                }
            }

            if (domNode instanceof Element && domNode.name === 'a' && domNode.attribs.href.includes(originalHidiveNewsLink)) {
                domNode.attribs.href = domNode.attribs.href.replace(originalHidiveNewsLink, "");
            }
        }
    };
    return <div className="content">{parse(content, options)}</div>;
};

const PostByline = ({byline}: { byline: Byline }) => {
    if (!byline) return <Fragment/>;

    const Name = () => {
        if (byline.url) {
            return <Link to={byline.url}>{byline.name}</Link>;
        }
        return <>byline.name</>;
    };

    return (
        <div className="byline">
            By: <Name/> - {byline.title}
        </div>
    );
};

const PostTags = ({tags}: { tags: Tag[] }) => {
    if (!tags || tags.length === 0) return null;

    const tagElements = tags.map(tag => (
        <Link to={`/tag/${tag.seoName}`} key={tag.seoName}>
            <Badge>
                {tag.name}
            </Badge>
        </Link>
    ));

    return <div className='text-center'>{tagElements}</div>;
};

const AuthorBrief = ({byline}: { byline: Byline }) => {
    if (!byline) return <Fragment/>;

    return (
        <div className="brief">
            <div className="brief-intro">About {byline.name}</div>
            <div className="brief-desc">{byline.brief}</div>
        </div>
    );
};

const PostDisclaimer = ({disclaimer}: {disclaimer: string}) => {
    return (
        <div className='text-center mt-5'>
            <p>{disclaimer}</p>
        </div>
    )
}

export default PostDetail;
