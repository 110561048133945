import {Provider} from "react-redux";
import {store} from "./app/store";
import Analytics from "./helmets/Analytics";
import ScrollToTop from "./hooks/ScrollToTop";
import {Outlet} from "react-router-dom";
import React from "react";

const App = () => (
    <Provider store={store}>
        <Analytics/>
        <ScrollToTop/>
        <Outlet/>
    </Provider>
)

export default App;