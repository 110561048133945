import {newsApiSlice, PostExcerpt} from "../features/news-api-slice";
import React, {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import PostSummary from "./postSummary";
import '../styles/posts.scss';
import {Button, Fade, Row} from "reactstrap";
import {Notice, NoticeType} from "./Notice";

const take = 9;

const PostsContainer = () => {
    let [page, setPage] = useState(1);
    let [hasMore, setHasMore] = useState(true);
    let filter = useAppSelector(state => state.filter);
    let [postDetails, setPostDetails] = useState<PostExcerpt[]>([]);
    let skip = take * (page - 1);

    useEffect(() => {
        setPage(1);
        setPostDetails([]);
        setHasMore(true);
    }, [filter]);

    const selectedPosts = useMemo(
        () => newsApiSlice.endpoints?.fetchSummaryPosts.select({take, skip, filter}),
        [filter, skip]
    );

    const {data, isLoading} = useAppSelector(selectedPosts);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const result = dispatch(
            newsApiSlice.endpoints?.fetchSummaryPosts.initiate({take, skip, filter}));

        return result.unsubscribe;
    }, [dispatch, filter, skip]);


    useEffect(() => {
        if (!data) return;

        let addPosts = (posts: PostExcerpt[]) => {
            if (postDetails.length === page * take) return;

            if (page === 1) {
                setPostDetails(posts);
            } else {
                setPostDetails(postDetails.concat(posts));
            }

            if (posts.length !== take) setHasMore(false);
        }
        if (hasMore) addPosts(data);
    }, [data, page, postDetails]);

    if (isLoading && postDetails.length === 0) return <Notice type={NoticeType.Loading} />;

    if (!postDetails.length) return <Notice type={NoticeType.NoNews} />;

    return (
        <Fade>
            <Row>
                {postDetails.map(post => <PostSummary key={post.id} post={post}/>)}
            </Row>
            <Button
                color='primary'
                onClick={() => setPage(page + 1)}
                disabled={!hasMore}>Load More</Button>
        </Fade>
    );
};

export default PostsContainer;
