import {useParams} from "react-router-dom";
import {useFetchPostQuery} from "../features/news-api-slice";
import React from "react";
import Navigation from "../components/Navigation";
import PostDetail from "../components/PostDetail";
import {useAppSelector} from "../app/hooks";

const DetailRoute = () => {
    const {seoUrl} = useParams();
    let filter = useAppSelector(state => state.filter);
    const {data = null, isFetching} = useFetchPostQuery({alias: seoUrl, filter});

    let url = () => {
        let url = '';

        if (filter.tag) url += `/tag/${filter.tag}`;
        else {
            if (filter.archive) url += `/${filter.archive.year}/${filter.archive.month}`;
            if (filter.category) url += `/${filter.category}`;
        }

        return !!url ? url : '/';
    }

    let content = isFetching ?
        null :
        data ?
            <PostDetail details={data}/> :
            <p>No Content...</p>

    return (
        <Navigation home={url()} loading={isFetching} next={data?.navigation.next} previous={data?.navigation.previous}>
            {content}
        </Navigation>
    )
};

export default DetailRoute;
