import {Card, CardBody, Col, Fade, Row} from "reactstrap";
import Config from "../config";

export enum NoticeType { Loading, NoNews, ServiceUnavailable}

export interface NoticeProps {
    type:NoticeType
}

const NoNews = () => <>
    <h2 style={{textTransform: "none"}}>
        No current news of this type available at this time.
    </h2>
    <p>
        Check back often for the latest HIDIVE news, updates and events!
    </p>
</>

const Loading = () => <h2 style={{textTransform: "none"}}>
    Loading...
</h2>

const ServiceUnavailable = () => <>
    <h2 style={{textTransform: "none"}}>
        Service Unavailable
    </h2>
    <p>
        Check back later...
    </p>
</>


export const Notice = ({type}: NoticeProps) => {

    const DisplayNotice = () => {
        switch (type) {
            case NoticeType.Loading:
                return <Loading/>;
            case NoticeType.NoNews:
                return <NoNews/>;
            case NoticeType.ServiceUnavailable:
                return <ServiceUnavailable/>;
        }

    }

    return (
        <Fade in={true}>
            <Row className='my-5'>
                <Col lg={{
                    offset: 1,
                    size: 10
                }}>
                    <Card>
                        <CardBody>
                            <Col lg={{
                                offset: 2,
                                size: 8
                            }} className='text-center'>
                                <div className='py-3'>
                                    <DisplayNotice/>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fade>
    )
}