import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Filter, FilterRequest} from "./filter-slice";
import {Params} from "react-router-dom";
import Config from "../config";

export interface PostExcerpt {
    id: number;
    title: string;
    excerpt: string;
    image: string;
    releaseDate: Date;
    seoUrl: string
}

export interface PostDetail {
    id: number;
    title: string;
    content: string;
    image: string;
    releaseDate: Date;
}

export interface Byline {
    brief: any;
    url: string;
    name: string;
    title: string;
}

export interface Tag {
    name: string;
    seoName: string;
}

export interface Configuration {
    categories: { name: string, seoName: string }[];
    banner: { url: string; link: string; };
    metaData: MetaData;
    linkResources: LinkResources;
    analytics: Analytics;
}

export interface Analytics {
    index: boolean;
    post: boolean;
    press: boolean;
    tag: boolean;
}

export interface LinkResources {
    home: string;
    simulcasts: string;
    dubs: string;
    series: string;
    movies: string;
}

export interface MetaData {
    title: string;
    description: string,
    keywords: string,
    type: string
}

export interface NewsDetails {
    post: PostDetail;
    byline: Byline;
    tags: Tag[];
    disclaimer: string;
    metaData: MetaData;
    navigation: { next: string | undefined, previous: string | undefined }
}

export interface PostDetailRequest {
    alias: string | undefined,
    filter: Filter
}

export const newsApiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${Config.apiUrl}/news/news`,
        method: 'POST'
    }),
    endpoints(build) {
        return {
            fetchSummaryPosts: build.query<PostExcerpt[], FilterRequest>({
                query(body) {
                    return {
                        url: '',
                        body
                    };
                }
            }),
            fetchPost: build.query<NewsDetails, PostDetailRequest>({
                query(body) {
                    return {
                        url: '/details',
                        body
                    };
                }
            }),
            fetchConfig: build.query<Configuration, Readonly<Params<string>>>({
                query(params) {
                    const resource = params.tag ?
                        `Tags.${params.tag}` :
                        params.category ? (params.category === "press" ? "Press" : `Category.${params.category}`)
                            : null;

                    let body: { resource: string | null } = {resource};
                    return {
                        url: '/config',
                        body
                    }
                }
            })
        }
    }
});

export const {useFetchPostQuery, useFetchConfigQuery} = newsApiSlice;
